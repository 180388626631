import { Linking, ScrollView, View } from "react-native";
import { List } from "react-native-paper";
import { getLocaleString } from "../../utils/getLocaleString";
import { HomeStackScreenProps } from "../Home/types";
import { useGetSubscriptionPortalSession } from "../../api/subscription";
import { useEffect, useState } from "react";
import BottleLoader from "../../components/BottleLoader";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { useGetSettings } from "../../api/settings";


const Settings = ({navigation}: HomeStackScreenProps<"Settings">) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const {isLoading: isSettingsLoading, data: settingsData} = useGetSettings();
  const {isLoading, data} = useGetSubscriptionPortalSession(
    !!settingsData?.subscription?.customerId &&
    !settingsData?.subscription?.store ||
    settingsData?.subscription?.store == "stripe"
  );

  useEffect(() => {
    getCurrentUser().then(({username}) => {
      if (username.includes('_')) {
        setIsExternalUser(true);
      }
      setLoading(false);
    });
  }, []);

  const openSubscriptionPortal = () => {
    if (isSettingsLoading || isLoading || !data || !settingsData) {
      setLoading(true);
      return;
    }
    
    setLoading(false);

    if (data.url) {
      Linking.openURL(data.url);
      return;
    }

    if (settingsData?.subscription?.managementUrl) {
      Linking.openURL(settingsData?.subscription?.managementUrl);
      return;
    }
  }

  return loading || isLoading || isSettingsLoading ? (
    <View>
      <BottleLoader />
    </View>
  ) : (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 4,
        height: '100%',
      }}>
      <ScrollView
        style={{width: '100%'}}
        contentContainerStyle={{alignItems: 'center'}}>
        <View style={{maxWidth: 600, width: '100%', height: '100%'}}>
          <List.Section>
            <List.Subheader>{getLocaleString('preferences')}</List.Subheader>
            <List.Item
              title={getLocaleString('location')}
              description={getLocaleString('locationDescription')}
              left={props => <List.Icon {...props} icon="map-marker-radius" />}
              onPress={() => navigation.push('LocationSettings')}
            />
            <List.Item
              title={getLocaleString('notifications')}
              description={getLocaleString('notificationsDescription')}
              left={props => <List.Icon {...props} icon="bell" />}
              onPress={() => navigation.push('NotificationSettings')}
            />
          </List.Section>

          <List.Section>
            <List.Subheader>{getLocaleString('account')}</List.Subheader>

            {!isExternalUser && (
              <List.Item
                title={getLocaleString('security')}
                description={getLocaleString('securityDescription')}
                left={props => <List.Icon {...props} icon="lock" />}
                onPress={() => navigation.push('SecuritySettings')}
              />
            )}

            <List.Item
              title={getLocaleString('subscription')}
              description={getLocaleString('subscriptionDescription')}
              onPress={openSubscriptionPortal}
              left={props => <List.Icon {...props} icon="credit-card" />}
            />
            <List.Item
              title={getLocaleString('signOut')}
              onPress={async () => await signOut()}
              left={props => <List.Icon {...props} icon="logout" />}
            />
          </List.Section>
        </View>
      </ScrollView>
    </View>
  );
};


export default Settings;
